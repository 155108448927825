<template>
    <b-row>
        <b-col md="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay :show="unitLoad">
                        <div class="application-form-wrapper">
                            <div class="application-itmes">
                                <b-row>
                                    <b-col sm="12">
                                        <div class="group-form-card">
                                            <b-card>
                                                <b-form-group
                                                label-cols-lg="3"
                                                label-size="lg"
                                                label-class="font-weight-bold pt-0"
                                                class="mb-0">
                                                <template v-slot:label>
                                                    <p>{{ $t('externalTradeFair.docs_to_be_attached') }}</p>
                                                    <!-- <small class="text-muted">
                                                        {{ $i18n.locale == 'en' ? '[Maximum file size is 500 KB and file format is PNG/JPEG/JPG/PDF]' : '[সর্বোচ্চ ফাইল সাইজ 500 KB এবং ফাইল ফরমেট PNG/JPEG/JPG/PDF]' }}
                                                    </small> -->
                                                </template>
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('externalTradeFair.applicant_photo', 'en')" vid="applicant_photo"
                                                                :rules="`${($route.query.id && tmp_attach?.applicant_photo) ? '' : 'required'}`" v-slot="{ errors }">
                                                                <b-form-group label-for="applicant_photo">
                                                                    <template v-slot:label>
                                                                        {{ $n(1) + '. ' + $t('externalTradeFair.applicant_photo')}} <span
                                                                            v-if="!$route.query.id || !tmp_attach?.applicant_photo" class="text-danger">*</span>
                                                                        <span v-if="tmp_attach?.applicant_photo">
                                                                            <a target="_blank" :href="baseUrl + 'storage/' + tmp_attach?.applicant_photo"
                                                                                title="Download Attachment" class="ml-2">{{ $t('globalTrans.download')}} <i
                                                                                    class="ri-download-cloud-fill"></i></a>
                                                                        </span>
                                                                    </template>
                                                                    <b-form-file plain accept=".png,.jpeg,.jpg" v-model="attach.applicant_photo"
                                                                        v-on:change="onFileChange($event, 1)" :state="errors[0] ? false : (valid ? true : null)"></b-form-file>
                                                                        <small class="validation_msg" style="margin-top: 5px; font-size: 12px;"><i :title="$t('externalTradeFair.file_size')" class="ri-folder-info-line"></i><b> {{$t('externalTradeFair.file_size')}}</b></small>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    <!-- <div class="invalid-feedback d-block text-muted">
                                                                        {{ $i18n.locale == 'en' ? '[Maximum file size is 500 KB and file format is PNG/JPEG/JPG]' : '[সর্বোচ্চ ফাইল সাইজ 500 KB এবং ফাইল ফরমেট PNG/JPEG/JPG]' }}
                                                                    </div> -->
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('externalTradeFair.trade_license', 'en')" vid="trade_license"
                                                                :rules="`${($route.query.id && tmp_attach?.trade_license) ? '' : 'required'}`" v-slot="{ errors }">
                                                                <b-form-group label-for="trade_license">
                                                                    <template v-slot:label>
                                                                        {{ $n(2) + '. ' + $t('externalTradeFair.trade_license')}} <span v-if="!$route.query.id || !tmp_attach?.trade_license"
                                                                            class="text-danger">*</span>
                                                                        <span v-if="tmp_attach?.trade_license">
                                                                            <a target="_blank" :href="baseUrl + 'storage/' + tmp_attach?.trade_license"
                                                                                title="Download Attachment" class="ml-2">{{ $t('globalTrans.download')}} <i
                                                                                    class="ri-download-cloud-fill"></i></a>
                                                                        </span>
                                                                    </template>
                                                                    <b-form-file plain accept=".doc,.docx,.pdf" v-model="attach.trade_license"
                                                                        v-on:change="onFileChange($event, 2)" :state="errors[0] ? false : (valid ? true : null)"></b-form-file>
                                                                    <small class="validation_msg" style="margin-top: 5px; font-size: 12px;"><i :title="$t('externalTradeFair.max_file')" class="ri-folder-info-line"></i><b> {{$t('externalTradeFair.max_file')}}</b></small>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('externalTradeFair.vat_regi_certificate', 'en')" vid="vat_regi_certificate"
                                                                :rules="`${($route.query.id && tmp_attach?.vat_regi_certificate) ? '' : 'required'}`" v-slot="{ errors }">
                                                                <b-form-group label-for="vat_regi_certificate">
                                                                    <template v-slot:label>
                                                                        {{ $n(3) + '. ' + $t('externalTradeFair.vat_regi_certificate')}} <span v-if="!$route.query.id || !tmp_attach?.vat_regi_certificate"
                                                                            class="text-danger">*</span>
                                                                        <span v-if="tmp_attach?.vat_regi_certificate">
                                                                            <a target="_blank" :href="baseUrl + 'storage/' + tmp_attach?.vat_regi_certificate"
                                                                                title="Download Attachment" class="ml-2">{{ $t('globalTrans.download')}} <i
                                                                                    class="ri-download-cloud-fill"></i></a>
                                                                        </span>
                                                                    </template>
                                                                    <b-form-file plain accept=".doc,.docx,.pdf" v-model="attach.vat_regi_certificate"
                                                                        v-on:change="onFileChange($event, 3)" :state="errors[0] ? false : (valid ? true : null)"></b-form-file>
                                                                        <small class="validation_msg" style="margin-top: 5px; font-size: 12px;"><i :title="$t('externalTradeFair.max_file')" class="ri-folder-info-line"></i><b> {{$t('externalTradeFair.max_file')}}</b></small>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    <!-- <div class="invalid-feedback d-block text-muted">
                                                                        {{ $t('externalTradeFair.max_file') }}
                                                                    </div> -->
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col v-if="circular_type == 1" sm="6">
                                                            <ValidationProvider :name="$t('externalTradeFair.export_regi_certificate', 'en')" vid="export_regi_certificate"
                                                                rules="" v-slot="{ errors }">
                                                                <b-form-group label-for="export_regi_certificate">
                                                                    <template v-slot:label>
                                                                        {{ $n(4) + '. ' + $t('externalTradeFair.export_regi_certificate')}}
                                                                        <span v-if="tmp_attach?.export_regi_certificate">
                                                                            <a target="_blank" :href="baseUrl + 'storage/' + tmp_attach?.export_regi_certificate"
                                                                                title="Download Attachment" class="ml-2">{{ $t('globalTrans.download')}} <i
                                                                                    class="ri-download-cloud-fill"></i></a>
                                                                        </span>
                                                                    </template>
                                                                    <b-form-file plain accept=".doc,.docx,.pdf" v-model="attach.export_regi_certificate"
                                                                        v-on:change="onFileChange($event, 4)" :state="errors[0] ? false : (valid ? true : null)"></b-form-file>
                                                                        <small class="validation_msg" style="margin-top: 5px; font-size: 12px;"><i :title="$t('externalTradeFair.max_file')" class="ri-folder-info-line"></i><b> {{$t('externalTradeFair.max_file')}}</b></small>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    <!-- <div class="invalid-feedback d-block text-muted">
                                                                        {{ $t('externalTradeFair.max_file') }}
                                                                    </div> -->
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col v-if="circular_type == 1" sm="6">
                                                            <ValidationProvider :name="$t('externalTradeFair.prc_certificate', 'en')" vid="prc_certificate" rules=""
                                                                v-slot="{ errors }">
                                                                <b-form-group label-for="prc_certificate">
                                                                    <template v-slot:label>
                                                                        {{ $n(5) + '. ' + $t('externalTradeFair.prc_certificate')}}
                                                                        <span v-if="tmp_attach?.prc_certificate">
                                                                            <a target="_blank" :href="baseUrl + 'storage/' + tmp_attach?.prc_certificate"
                                                                                title="Download Attachment" class="ml-2">{{ $t('globalTrans.download')}} <i
                                                                                    class="ri-download-cloud-fill"></i></a>
                                                                        </span>
                                                                    </template>
                                                                    <b-form-file plain accept=".doc,.docx,.pdf" v-model="attach.prc_certificate"
                                                                        v-on:change="onFileChange($event, 5)" :state="errors[0] ? false : (valid ? true : null)"></b-form-file>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    <small class="validation_msg" style="margin-top: 5px; font-size: 12px;"><i :title="$t('externalTradeFair.max_file')" class="ri-folder-info-line"></i><b> {{$t('externalTradeFair.max_file')}}</b></small>
                                                                    <!-- <div class="invalid-feedback d-block text-muted">
                                                                        {{ $t('externalTradeFair.max_file') }}
                                                                    </div> -->
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('externalTradeFair.tax_certificate', 'en')" vid="tax_certificate"
                                                                :rules="`${($route.query.id && tmp_attach?.tax_certificate) ? '' : 'required'}`" v-slot="{ errors }">
                                                                <b-form-group label-for="tax_certificate">
                                                                    <template v-slot:label>
                                                                        {{ $n(circular_type == 1 ? 6 : 4) + '. ' + $t('externalTradeFair.tax_certificate')}} <span v-if="!$route.query.id || !tmp_attach?.tax_certificate"
                                                                            class="text-danger">*</span>
                                                                        <span v-if="tmp_attach?.tax_certificate">
                                                                            <a target="_blank" :href="baseUrl + 'storage/' + tmp_attach?.tax_certificate"
                                                                                title="Download Attachment" class="ml-2">{{ $t('globalTrans.download')}} <i
                                                                                    class="ri-download-cloud-fill"></i></a>
                                                                        </span>
                                                                    </template>
                                                                    <b-form-file plain accept=".doc,.docx,.pdf" v-model="attach.tax_certificate"
                                                                        v-on:change="onFileChange($event, 6)" :state="errors[0] ? false : (valid ? true : null)"></b-form-file>
                                                                        <small class="validation_msg" style="margin-top: 5px; font-size: 12px;"><i :title="$t('externalTradeFair.max_file')" class="ri-folder-info-line"></i><b> {{$t('externalTradeFair.max_file')}}</b></small>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    <!-- <div class="invalid-feedback d-block text-muted">
                                                                        {{ $t('externalTradeFair.max_file') }}
                                                                    </div> -->
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col v-if="circular_type == 1" sm="6">
                                                            <ValidationProvider :name="$t('externalTradeFair.chamber_certificate', 'en')" vid="chamber_certificate"
                                                                rules="" v-slot="{ errors }">
                                                                <b-form-group label-for="chamber_certificate">
                                                                    <template v-slot:label>
                                                                        {{ $n(7) + '. ' + $t('externalTradeFair.chamber_certificate')}}
                                                                        <span v-if="tmp_attach?.chamber_certificate">
                                                                            <a target="_blank" :href="baseUrl + 'storage/' + tmp_attach?.chamber_certificate"
                                                                                title="Download Attachment" class="ml-2">{{ $t('globalTrans.download')}} <i
                                                                                    class="ri-download-cloud-fill"></i></a>
                                                                        </span>
                                                                    </template>
                                                                    <b-form-file plain accept=".doc,.docx,.pdf" v-model="attach.chamber_certificate"
                                                                        v-on:change="onFileChange($event, 7)" :state="errors[0] ? false : (valid ? true : null)"></b-form-file>
                                                                        <small class="validation_msg" style="margin-top: 5px; font-size: 12px;"><i :title="$t('externalTradeFair.max_file')" class="ri-folder-info-line"></i><b> {{$t('externalTradeFair.max_file')}}</b></small>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    <!-- <div class="invalid-feedback d-block text-muted">
                                                                        {{ $t('externalTradeFair.max_file') }}
                                                                    </div> -->
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('externalTradeFair.bank_certificate', 'en')" vid="bank_certificate"
                                                                :rules="`${($route.query.id && tmp_attach?.bank_certificate) ? '' : 'required'}`" v-slot="{ errors }">
                                                                <b-form-group label-for="bank_certificate">
                                                                    <template v-slot:label>
                                                                        {{ $n(circular_type == 1 ? 8 : 5) + '. ' + $t('externalTradeFair.bank_certificate')}} <span v-if="!$route.query.id || !tmp_attach?.bank_certificate"
                                                                            class="text-danger">*</span>
                                                                        <span v-if="tmp_attach?.bank_certificate">
                                                                            <a target="_blank" :href="baseUrl + 'storage/' + tmp_attach?.bank_certificate"
                                                                                title="Download Attachment" class="ml-2">{{ $t('globalTrans.download')}} <i
                                                                                    class="ri-download-cloud-fill"></i></a>
                                                                        </span>
                                                                    </template>
                                                                    <b-form-file plain accept=".doc,.docx,.pdf" v-model="attach.bank_certificate"
                                                                        v-on:change="onFileChange($event, 8)" :state="errors[0] ? false : (valid ? true : null)"></b-form-file>
                                                                        <small class="validation_msg" style="margin-top: 5px; font-size: 12px;"><i :title="$t('externalTradeFair.max_file')" class="ri-folder-info-line"></i><b> {{$t('externalTradeFair.max_file')}}</b></small>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    <!-- <div class="invalid-feedback d-block text-muted">
                                                                        {{ $t('externalTradeFair.max_file') }}
                                                                    </div> -->
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('externalTradeFair.nid_attachment', 'en')" vid="nid_attachment"
                                                                :rules="`${($route.query.id && tmp_attach?.nid_attachment) ? '' : 'required'}`" v-slot="{ errors }">
                                                                <b-form-group label-for="nid_attachment">
                                                                    <template v-slot:label>
                                                                        {{ $n(circular_type == 1 ? 9 : 6) + '. ' + $t('externalTradeFair.nid_attachment')}} <span v-if="!$route.query.id || !tmp_attach?.bank_certificate"
                                                                            class="text-danger">*</span>
                                                                        <span v-if="tmp_attach?.nid_attachment">
                                                                            <a target="_blank" :href="baseUrl + 'storage/' + tmp_attach?.nid_attachment"
                                                                                title="Download Attachment" class="ml-2">{{ $t('globalTrans.download')}} <i
                                                                                    class="ri-download-cloud-fill"></i></a>
                                                                        </span>
                                                                    </template>
                                                                    <b-form-file plain accept=".doc,.docx,.pdf" v-model="attach.nid_attachment"
                                                                        v-on:change="onFileChange($event, 9)" :state="errors[0] ? false : (valid ? true : null)"></b-form-file>
                                                                        <small class="validation_msg" style="margin-top: 5px; font-size: 12px;"><i :title="$t('externalTradeFair.max_file')" class="ri-folder-info-line"></i><b> {{$t('externalTradeFair.max_file')}}</b></small>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    <!-- <div class="invalid-feedback d-block text-muted">
                                                                        {{ $t('externalTradeFair.max_file') }}
                                                                    </div> -->
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('globalTrans.signature', 'en')" vid="representative_signature"
                                                                :rules="`${($route.query.id && tmp_attach?.signature) ? '' : 'required'}`" v-slot="{ errors }">
                                                                <b-form-group label-for="representative_signature">
                                                                    <template v-slot:label>
                                                                        {{ $n(circular_type == 1 ? 10 : 7) + '. ' + $t('globalTrans.signature')}} <span
                                                                            v-if="!$route.query.id || !tmp_attach?.signature" class="text-danger">*</span>
                                                                        <span v-if="tmp_attach?.signature">
                                                                            <a target="_blank" :href="baseUrl + 'storage/' + tmp_attach?.signature"
                                                                                title="Download Attachment" class="ml-2">{{ $t('globalTrans.download')}} <i
                                                                                    class="ri-download-cloud-fill"></i></a>
                                                                        </span>
                                                                    </template>
                                                                    <b-form-file plain accept=".png,.jpeg,.jpg" v-model="attach.signature"
                                                                        v-on:change="onFileChange($event, 10)" :state="errors[0] ? false : (valid ? true : null)"></b-form-file>
                                                                        <small class="validation_msg" style="margin-top: 5px; font-size: 12px;"><i :title="$t('externalTradeFair.file_size')" class="ri-folder-info-line"></i><b> {{$t('externalTradeFair.file_size')}}</b></small>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    <!-- <div class="invalid-feedback d-block text-muted">
                                                                        {{ $i18n.locale == 'en' ? '[Maximum file size is 500 KB and file format is PNG/JPEG/JPG]' : '[সর্বোচ্চ ফাইল সাইজ 500 KB এবং ফাইল ফরমেট PNG/JPEG/JPG]' }}
                                                                    </div> -->
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                            </b-card>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                        <b-row>
                            <b-col sm="12">
                                <ValidationProvider name="Licence Issue" vid="licence_issue" rules="required|min_value:1">
                                    <b-form-group
                                        class="row mb-4"
                                        label-cols-sm="12"
                                        label-for="licence_issue"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <b-form-checkbox
                                            id="licence_issue"
                                            v-model="attachments.license_issue"
                                            name="checkbox-1"
                                            :value="1"
                                            :unchecked-value="0"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template>
                                            <p style="margin-top: 2px;font-weight: 600;">{{ $t('externalTradeFair.declaration_detail') }}</p>
                                        </template>
                                        </b-form-checkbox>
                                        <div class="invalid-feedback" style="display: block !important">
                                            {{ errors[0] ? 'Please click your acknowledgement' : '' }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { ditfTabLastStoreApi } from '../../../api/routes'

export default {
    props: ['app_id', 'attachments', 'app_status', 'circular_type', 'baseUrl'],
    data () {
        return {
            errors: [],
            valid: null,
            unitLoad: false,
            tmp_attach: {},
            attach: {
                applicant_photo: [],
                trade_license: [],
                vat_regi_certificate: [],
                export_regi_certificate: [],
                prc_certificate: [],
                tax_certificate: [],
                chamber_certificate: [],
                bank_certificate: [],
                nid_attachment: [],
                signature: []
            }
        }
    },
    created () {
        if (this.$route.query.id) {
            this.tmp_attach = JSON.parse(JSON.stringify(this.attachments))
            // this.attach = JSON.parse(JSON.stringify(this.attachments))
        }
    },
    watch: {
        currentLocale: function (newVal, oldVal) {
            if (newVal !== oldVal) {
            }
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        onFileChange (event, count) {
            const input = event.target
            if (input.files && input.files[0]) {
                // const reader = new FileReader()
                // reader.onload = (e) => {
                    // const base64File = e.target.result
                    const base64File = event.target.files[0]
                    if (count === 1) {
                        this.attach.applicant_photo = base64File
                    } else if (count === 2) {
                        this.attach.trade_license = base64File
                    } else if (count === 3) {
                        this.attach.vat_regi_certificate = base64File
                    } else if (count === 4) {
                        this.attach.export_regi_certificate = base64File
                    } else if (count === 5) {
                        this.attach.prc_certificate = base64File
                    } else if (count === 6) {
                        this.attach.tax_certificate = base64File
                    } else if (count === 7) {
                        this.attach.chamber_certificate = base64File
                    } else if (count === 8) {
                        this.attach.bank_certificate = base64File
                    } else if (count === 9) {
                        this.attach.nid_attachment = base64File
                    } else if (count === 10) {
                        this.attach.signature = base64File
                    }
                // }
                // reader.readAsDataURL(input.files[0])
            } else {
                if (count === 1) {
                    this.attach.applicant_photo = []
                } else if (count === 2) {
                    this.attach.trade_license = []
                } else if (count === 3) {
                    this.attach.vat_regi_certificate = []
                } else if (count === 4) {
                    this.attach.export_regi_certificate = []
                } else if (count === 5) {
                    this.attach.prc_certificate = []
                } else if (count === 6) {
                    this.attach.tax_certificate = []
                } else if (count === 7) {
                    this.attach.chamber_certificate = []
                } else if (count === 8) {
                    this.attach.bank_certificate = []
                } else if (count === 9) {
                    this.attach.nid_attachment = []
                } else if (count === 10) {
                    this.attach.signature = []
                }
            }
        },
        async validatinCechk () {
            var check = await this.$refs.form.validate()
            return check
        },
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.unitLoad = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }
                this.attachments.app_id = this.app_id
                this.attachments.app_status = this.app_status
                if (this.$route.query.id) {
                    this.attachments.edit_mode = 1
                } else {
                    this.attachments.edit_mode = 0
                }

                const formData = new FormData()
                Object.keys(this.attachments).map(key => {
                    const keyExist = Object.keys(this.attach).some(ky => ky === key)
                    if (keyExist) {
                        formData.append(key, this.attach[key])
                    } else {
                        formData.append(key, this.attachments[key])
                    }
                })

                const result = await RestApi.postData(internationalTradeFairServiceBaseUrl, ditfTabLastStoreApi, formData)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                this.unitLoad = false
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: result.message !== undefined ? result.message : this.$t('globalTrans.form_error_msg'),
                        color: '#ee5253'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            }
        }
    }
}
</script>
